<template>
  <table class="table-component" :style="style" @scroll="scroll">
    <thead>
      <tr>
        <th
          v-for="(column, idx) in columns"
          :key="idx"
          :data-column="column.key"
          style="z-index: 9;"
        >
          <div @click="sortBy(column)" style="cursor: pointer;">
            <slot :name="`header-${column.key}`" :column="column">
              <span>{{ column.title }}</span>
            </slot>
          </div>

          <template v-if="column.sortable">
            <sorting-arrows
              class="sorting-arrows"
              :enabled="order && order.column === column.key"
              :direction="order ? order.direction : null"
              @click.native="sortBy(column)"
            />
          </template>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, idx) in data" :key="idx">
        <template>
          <td v-for="(column, idx) in columns" :key="idx" :data-column="column.key">
            <slot :name="`row-${column.key}`" :item="item">
              <span>{{ item[column.key] }}</span>
            </slot>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import SortingArrows from "@/components/Icons/SortingArrows";

export default {
  name: "table-component",

  components: {
    SortingArrows
  },

  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    gridTemplate: {
      type: Array
    },
    order: {
      column: String,
      direction: String
    }
  },

  methods: {
    sortBy(column) {
      if (column.sortable) {
        this.$emit("sort-by", column.key);
      }
    },

    scroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        Math.round(scrollTop + clientHeight) >= scrollHeight &&
        !this.blockScroll
      ) {
        this.$emit("hit-bottom");
      }
    }
  },

  computed: {
    style() {
      let tpl = `repeat(${this.columns.length}, 1fr)`;
      if (
        this.gridTemplate &&
        this.gridTemplate.length === this.columns.length
      ) {
        tpl = this.gridTemplate.join(" ");
      }
      return {
        "grid-template-columns": tpl
      };
    }
  }
};
</script>

<style lang="scss" scoped>

.table-component {
  display: grid;
  grid-auto-rows: min-content;

  overflow-y: scroll;
  position: relative;
  border-collapse: collapse;
  min-width: 100%;

  thead,
  tbody,
  tr {
    display: contents;
    text-align: left;
    widows: 100%;
  }

  th,
  td {
    display: flex;
    align-items: center;
    box-shadow: inset 0px -1px 0px $grey50;
    height: 58px;
  }

  th {
    @include text-tiny;
    height: 32px;
    color: $grey70;
    position: sticky;
    top: 0;
    background: $white;

    .sorting-arrows {
      margin-left: 5px;
    }
  }

  td {
    @include text-body;
    height: 58px;
    color: $notBlack;

    span {
      max-width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
