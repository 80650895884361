<template>
  <div id="app">
    <options-block
        :profile-list="profileList"
        :selected-profile="selectedProfile"
        :profile-modal="profileModal"
        class="options"
        @profile-changed="profileChanged"
        @export-csv="exportCSV"
        @edit-profile="editProfile"
        @remove-profile="removeProfile"/>
    <listing-devices-block
        :loading="listingDeviceLoading"
        :device-list="filteredDeviceList"
        class="devices"/>
    <router-view
        :device-list="deviceList"
        :profile-list="profileList"
        :selected-profile="selectedProfile"
        :config-list="configList"
        class="router" @import="importFile" @update="getProfiles"/>
    <sweet-modal ref="modal">
      <div>Deleting profile: {{ deletingProfile && deletingProfile.type }}</div>
      <div v-if="deletingProfile && getDevicesCount(deletingProfile.id) > 0">The profile cannot be deleted. Remove {{ deletingProfile && getDevicesCount(deletingProfile.id) }} devices associated with this profile</div>
      <div v-else>Are you sure you want to delete this profile?</div>
      <button-input :disabled="deletingProfile && getDevicesCount(deletingProfile.id) > 0" @click.native="deleteProfile">Delete</button-input>
    </sweet-modal>
  </div>
</template>

<script>
import OptionsBlock from "@/view/OptionsBlock";
import ListingDevicesBlock from "@/view/ListingDevicesBlock";

import ButtonInput from "@/components/Inputs/ButtonInput";

import {ProfilesApi, DevicesApi, ConfigsApi} from '@/api'
import CSV from '@/helpers/csv'
// import Validation from "@/helpers/validation";

export default {
  name: 'App',
  components: {
    ListingDevicesBlock,
    OptionsBlock,
    ButtonInput
  },
  data () {
    return {
      listingDeviceLoading: false,
      profileList: [],
      deviceList: [],
      configList: [],
      selectedProfile: null,
      deletingProfile: null,
      profileModal: false
    }
  },
  computed: {
    filteredDeviceList () {
      if (this.selectedProfile && this.deviceList.length !== 0) {
        return this.deviceList.filter(device => device.profileId === this.selectedProfile.id)
      } else {
        return []
      }
    }
  },
  created () {
    this.getProfiles()
  },
  methods: {
    async getProfiles () {
      console.log('getProfiles')
      this.profileList = await ProfilesApi.getProfiles().then(r => r.data.data)
      console.log(this.profileList)

      this.listingDeviceLoading = true
      this.deviceList = await this.getDevices()
      // this.deviceList = []
      this.listingDeviceLoading = false
      console.log(this.deviceList)
      this.configList = await this.getConfigs()

      if (this.$route.path.includes('device') && this.$route.params.id && !this.selectedProfile) {
        const profileId = this.deviceList.find(device => device.uid === this.$route.params.id)
        console.log('profileId: %o', profileId)
        this.selectedProfile = this.profileList.find(profile => profile.id === profileId.profileId)
      } else if (this.$route.path.includes('profile') && this.$route.params.id && !this.selectedProfile) {
        this.selectedProfile = this.profileList.find(profile => profile.id === parseInt(this.$route.params.id))
      }
    },
    getDevices () {
      console.log('getDevices')
      return DevicesApi.getDevices().then(r => r.data.data)
    },
    getConfigs () {
      return ConfigsApi.getConfigs().then(r => r.data.data)
    },
    profileChanged (value) {
      this.selectedProfile = value
      if (this.$route.fullPath !== '/') {
        this.$router.push(`/`)
      }
    },
    exportCSV () {
      console.log(this.selectedProfile)
      CSV.exportCSV(this.selectedProfile)
    },
    importFile (result) {
      let devicesForSaving = CSV.importCSV(result, this.selectedProfile.id)
      let newDevices = devicesForSaving.map(device => {
        // device.valid = Validation.valid(device, 'valid')
        device.new = true
        return device
      })

      devicesForSaving = newDevices.filter((device, index) => {
        // if (device.valid) {
          return devicesForSaving[index]
        // }
      })
      devicesForSaving = devicesForSaving.map(device => {
        console.log('device p: %o', device.params)
        device.params = device.params.map(p => {
          return {
            id: this.getCharacteristicId(p.id),
            value: p.value
          }
        })
        delete device.new
        // delete device.valid
        return device
      })
      console.log('devicesForSaving: %o', devicesForSaving)
      DevicesApi.createDevices(devicesForSaving).then(r => {
        console.log('create devices response: %o', r)
        this.getProfiles()
      }).catch(e => {
        console.log('create devices response: %o', e)
      })

      console.log('newDevices: %o',newDevices)
      this.deviceList.push(...newDevices)
      this.$router.push(`/device/${newDevices[0].uid}`)
    },
    getCharacteristicId (id) {
      console.log(this.selectedProfile)
      console.log(this.selectedProfile.characteristics.find(c => {
        return c.name === id
      }))
      return this.selectedProfile.characteristics.find(c => c.name === id).id
    },
    getDevicesCount (profileId) {
      return this.deviceList.filter(device => device.profileId === profileId).length
    },
    removeProfile (profile) {
      this.deletingProfile = profile
      this.$refs.modal.open()
    },
    deleteProfile () {
      this.profileModal = true
      this.$nextTick(() => {
        this.profileModal = false
      })
      this.$refs.modal.close()
      ProfilesApi.deleteProfile({ id: this.deletingProfile.id }).then(r => {
        console.log('deleting profile: ', r)
        this.getProfiles()

        this.$toast.success("Profile was deleted!");
      }).catch(e => {
        console.log('deleting profile: %o', e)
      })
      this.deletingProfile = null
    },
    editProfile (profile) {
      console.log('editProfile App')
      this.$router.push(`/profile/${profile.id}`)
      this.profileModal = true
      setTimeout(() => {
        this.profileModal = false
      }, 0)
    }
  }
}
</script>

<style>
#app {
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dddff1;
  width: 100%;
  height: 100vh;
  padding: 40px;

  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 140px 1fr;
  grid-template-areas: 'options options' 'devices router';
  grid-gap: 8px;

  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
.options {
  grid-area: options;
}
.devices {
  grid-area: devices;
}
.router {
  grid-area: router;
}
</style>
