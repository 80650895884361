<template>
  <div class="icon-input" :class="classes" :disabled="disabled" @click="focusInput">
    <div v-if="hasIcon" class="icon">
      <slot name="icon" />
    </div>

    <input
      ref="input"
      :type="type"
      :value="query"
      :placeholder="placeholder"
      @input="handleInput"
      @focus="onFocus"
      @blur="onBlur"
    />

    <div class="cross-icon" @click="clearInput" v-if="type=='search' && !!query">
      <cross-icon />
    </div>
  </div>
</template>

<script>
import CrossIcon from "@/components/Icons/Cross";

export default {
  components: { CrossIcon },

  props: {
    type: {
      type: String,
      default: "text"
    },

    disabled: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: ""
    },

    value: {
      type: null,
      default: ""
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.query = this.value;
      }
    }
  },

  methods: {
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    focusInput() {
      this.$refs.input.focus();
    },
    handleInput(event) {
      this.query = event.target.value;
      this.$emit("input", this.query);
    },
    clearInput() {
      this.query = "";
      this.$emit("input", "");
    }
  },

  computed: {
    classes() {
      return {
        focused: this.focused
      };
    },
    hasIcon() {
      return !!this.$slots.icon;
    }
  },

  data() {
    return {
      focused: false,
      query: ""
    };
  }
};
</script>

<style lang="scss" scoped>

.cross-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
}

.icon-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $grey50;
  border-radius: 6px;
  transition: $defaultTransition;
  outline: none;
  padding: 8px;
  background-color: $white;
  box-sizing: border-box;
  height: 40px;
  width: 100%;

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }

  .icon {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: $notBlack;
  }

  input {
    @include text-body;
    background-color: transparent;
    border: none;
    width: 100%;
    outline: none;
    margin: 0;
    padding: 0;
    height: 38px;
    box-sizing: border-box;
    color: $notBlack;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  &:hover {
    border-color: $grey50;
    box-shadow: $defaultShadow;

    .icon {
      color: $accentBlue;
    }

    input {
      color: $accentBlue;
    }
  }

  &.focused {
    box-shadow: $defaultShadow;
  }

  @-webkit-keyframes autofill {
    to {
      color: inherit;
      background: transparent;
    }
  }

  input::placeholder {
    color: $grey50;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-box-shadow: 0 0 0 1000px white inset;
    -webkit-text-fill-color: $notBlack;
  }
}
</style>
