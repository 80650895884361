import Axios from 'axios'

const Request = Axios.create({
  baseURL: ((process.env.VUE_APP_API_URL) ? process.env.VUE_APP_API_URL : "") + "/"
});
Request.interceptors.request.use((config) => {
  // const tokenId = store.getters['auth/authToken'];

  // if (!tokenId) {
  //   return config;
  // }

  const token = `Bearer cfdb065cae801fe24c813130c7b04351`;
  config.headers.common['Authorization'] = token;

  return config;
});

export default Request
