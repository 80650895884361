<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="13"
    viewBox="0 0 6 13"
    class="sorting-arrows-icon"
  >
    <g fill="none">
      <path
        fill="#6B6E8D"
        d="M0.000389415791,9.23059 C0.00132013733,9.07359 0.0354001373,8.91849 0.100390137,8.77559 C0.168530137,8.62099 0.279730137,8.48929 0.420700137,8.39619 C0.561680137,8.30309 0.726470137,8.25259 0.895390137,8.25059 L5.10539014,8.25059 C5.27432014,8.25259 5.43911014,8.30309 5.58008014,8.39619 C5.72106014,8.48929 5.83225014,8.62099 5.90039014,8.77559 C5.98441014,8.95349 6.01677014,9.15139 5.99379014,9.34669 C5.97080014,9.54209 5.89340014,9.72709 5.77039014,9.88059 L3.66539014,12.43059 C3.58579014,12.53059 3.48465014,12.61139 3.36952014,12.66689 C3.25438014,12.72239 3.12821014,12.75119 3.00038942,12.75119 C2.87258014,12.75119 2.74641014,12.72239 2.63127014,12.66689 C2.51614014,12.61139 2.41500014,12.53059 2.33539014,12.43059 L0.230390137,9.88059 C0.0813301373,9.69679 0.000110137333,9.46729 0.000389415791,9.23059 Z"
      />
      <polygon :fill="upperArrowColor" points="5 9.231 1.05 9.231 3 11.661" />
      <path
        fill="#6B6E8D"
        d="M6.00059086,3.52059 C5.99966014,3.67761 5.96558014,3.83266 5.90059014,3.97559 C5.83245014,4.13018 5.72125014,4.26188 5.58028014,4.35498 C5.43930014,4.44807 5.27451014,4.49862 5.10559014,4.50059 L0.895590137,4.50059 C0.726660137,4.49862 0.561870137,4.44807 0.420900137,4.35498 C0.279920137,4.26188 0.168730137,4.13018 0.100590137,3.97559 C0.0165701373,3.79772 -0.0157898627,3.59982 0.00719013733,3.40446 C0.0301801373,3.20909 0.107580137,3.02411 0.230590137,2.87059 L2.33559014,0.32059 C2.41519014,0.22059 2.51633014,0.13984 2.63146014,0.08433 C2.74660014,0.02883 2.87277014,2.78443935e-13 3.00059014,2.78443935e-13 C3.12840014,2.78443935e-13 3.25457014,0.02883 3.36971014,0.08433 C3.48484014,0.13984 3.58598014,0.22059 3.66559014,0.32059 L5.77059014,2.87059 C5.91965014,3.0544 6.00087014,3.28393 6.00059086,3.52059 Z"
      />
      <polygon :fill="bottomArrowColor" points="1.001 3.521 4.951 3.521 3.001 1.091" />
    </g>
  </svg>
</template>

<script>
const DEFAULT_COLOR = "#6B6E8D";
const ARROW_TRANSPARENT_COLOR = "#FFFFFF";

export default {
  name: "sorting-arrows",

  props: {
    enabled: {
      type: Boolean,
      default: false
    },

    direction: {
      type: String,
      default: null
    },

    borderColor: {
      type: String,
      default: DEFAULT_COLOR
    }
  },

  computed: {
    bottomArrowColor() {
      return this.enabled && this.direction === "desc"
        ? DEFAULT_COLOR
        : ARROW_TRANSPARENT_COLOR;
    },
    upperArrowColor() {
      return this.enabled && this.direction === "asc"
        ? DEFAULT_COLOR
        : ARROW_TRANSPARENT_COLOR;
    }
  }
};
</script>

<style lang="scss" scoped>
.sorting-arrows-icon {
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}
</style>