<template>
  <button class="button-input" :class="classes" :disabled="disabled">
    <div v-if="hasIcon" class="button-icon">
      <slot name="icon" />
    </div>
    <span v-if="hasText" class="button-title">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },

    narrow: {
      type: Boolean,
      default: false
    },

    primary: {
      type: Boolean,
      default: true
    },

    linear: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes() {
      return {
        disabled: this.disabled,
        active: this.active,
        linear: this.linear,
        primary: this.primary,
        narrow: this.autoNarrow,
        secondary: !this.primary
      };
    },

    hasIcon() {
      return !!this.$slots.icon;
    },

    hasText() {
      return !!this.$slots.default;
    },

    autoNarrow() {
      return this.narrow ? this.narrow : this.hasIcon && !this.hasText;
    }
  }
};
</script>

<style lang="scss" scoped>
.button-input {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  border: none;
  border-radius: 6px;
  transition: $defaultTransition;
  outline: none;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  height: 40px;

  &.narrow {
    padding: 0;

    .button-icon {
      margin: 0;
    }
  }

  &.primary {
    background: $accentBlue;
    color: $white;

    &:hover {
      background: $notBlack;
      box-shadow: $defaultShadow;
    }

    &:active {
      background: $darkBlue;
    }

    &:disabled {
      background: $grey30;
      color: $white;
      pointer-events: none;
    }
  }

  &.secondary {
    background: $white;
    color: $accentBlue;
    border: 1px solid $grey50;

    &:not(.linear) {
      &:hover {
        background: $white;
        box-shadow: $defaultShadow;
        border: 1px solid $grey50;
      }

      &:active {
        background: $grey10;
        border: 1px solid $grey10;
      }

      &:disabled {
        background: $white;
        border: 1px solid $grey10;
        color: $grey30;
        pointer-events: none;
      }
    }

    &.linear {
      color: $notBlack;

      &:hover {
        background: $white;
        box-shadow: $defaultShadow;
        border-color: $grey50;
      }

      &:active,
      &.active {
        border-color: $notBlack;
      }

      &.enabled {
        border-color: $accentBlue;
        color: $accentBlue;
      }

      &:disabled {
        background: $white;
        border-color: $grey10;
        color: $grey30;
        pointer-events: none;
      }
    }
  }

  .button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 0 4px;
  }

  .button-title {
    @include h2;
    flex: 1;
    font-weight: normal;
    // margin: 0 4px;
  }
}
</style>
