<template>
  <moe-block heading="Options" class="container">
    <template #content>
      <div class="container-content">
        <div class="profile">
          <label>Profile</label>
          <select-input
              placeholder="Select device profile"
              v-model="profile"
              :options="profileList"
              :searchable="false"
              @input="profileChanged"
          >
            <template #single-label>{{ profile.type }}</template>
            <template #option="{ option }">
              <div class="select-row">
                <span>{{ option.type }}</span>
                <span>
                  <pencil-icon class="pencil-icon" @click.native="editProfile(option)" />
                  <remove-icon class="remove-icon" @click.native="removeProfile(option)" />
                </span>
              </div>
            </template>
          </select-input>
        </div>
        <button-input @click.native="uploadConfigFile" :primary="false" :disabled="!profile">
          <template v-slot:icon>
            <upload-icon class="upload-icon" />
          </template>
          <template>
            <span>Upload config file</span>
          </template>
        </button-input>
        <button-input :primary="false" class="export-button" :disabled="!profile" @click.native="exportToCSV">
          <template v-slot:icon>
            <upload-icon class="upload-icon" />
          </template>
          <template>
            <span>Export to CSV</span>
          </template>
        </button-input>
        <button-input @click.native="addProfile">
          <template v-slot:icon>
            <plus-icon class="plus-icon"/>
          </template>
          <template>
            <span>Profile</span>
          </template>
        </button-input>
        <button-input :disabled="!profile" @click.native="addDevice">
          <template v-slot:icon>
            <plus-icon class="plus-icon"/>
          </template>
          <template>
            <span>Device</span>
          </template>
        </button-input>
      </div>
    </template>
  </moe-block>
</template>

<script>
import MoeBlock from "@/components/Blocks/MoeBlock";
import SelectInput from "@/components/Inputs/SelectInput";
import ButtonInput from "@/components/Inputs/ButtonInput";
import UploadIcon from "@/components/Icons/Upload";
import PlusIcon from "@/components/Icons/Plus";
import RemoveIcon from "@/components/Icons/Remove";
import PencilIcon from "@/components/Icons/Pencil";

// const generate = require('csv-generate')
// const assert = require('assert')
// import generate from "csv-generate/lib/sync"
// import assert from "assert"
// import transform from 'stream-transform/lib/sync'
// import Parser from 'json2csv'
// const transform = require('stream-transform')
// const assert = require('assert')

export default {
  name: "OptionsBlock",
  components: { MoeBlock, SelectInput, ButtonInput, UploadIcon, PlusIcon, RemoveIcon, PencilIcon },
  props: ['profileList', 'selectedProfile', 'profileModal'],
  data () {
    return {
      // profiles: ['profile_test_1', 'profile_test_2', 'profile_test_3'],
      profile: null,
      modal: false,
      modalText: ''
    }
  },
  watch: {
    selectedProfile (value) {
      console.log('selectedProfile: %o', value)
      this.profile = value
    },
    profileModal (value) {
      if (!value) {
        console.log('IF')
        this.modal = false
      }
      console.log('profileModal WATCH: %o', value)
      console.log('modal WATCH: %o', this.modal)
    }
  },
  methods: {
    profileChanged (value, event) {
      console.log(value, event)
      if (!this.modal) {
        this.$emit('profile-changed', value)
      }
    },
    addProfile () {
      this.$router.push('/profile')
    },
    addDevice () {
      this.$router.push('/device')
    },
    uploadConfigFile () {
      this.$router.push('/upload')
    },
    exportToCSV () {
      this.$emit('export-csv')
      // const records = generate({
      //   seed: 1,
      //   objectMode: true,
      //   columns: 2,
      //   length: 2
      // })
      // assert.deepEqual(records, [
      //   [ 'OMH', 'ONKCHhJmjadoA' ],
      //   [ 'D', 'GeACHiN' ]
      // ])
      // console.log(records)

      // let row = ''
      // const output = []
      // const transformer = transform(function(data){
      //   data.push(data.shift())
      //   return data
      // })
      // transformer.on('readable', function(){
      //   console.log(row = transformer.read())
      //   if (row) {
      //     output.push(row)
      //   }
      // })
      // transformer.on('error', function(err){
      //   console.error(err.message)
      // })
      // transformer.on('finish', function(){
      //   assert.deepEqual(output, [
      //     [ '2', '3', '4', '1' ],
      //     [ 'b', 'c', 'd', 'a' ]
      //   ])
      // })
      // transformer.write(['1','2','3','4'])
      // transformer.write(['a','b','c','d'])
      // transformer.end()
      //
      // console.log(assert)


      // const records = transform([
      //   [ 'a', 'b', 'c', 'd' ],
      //   [ '1', '2', '3', '4' ]
      // ], function(record){
      //   record.push(record.shift())
      //   return record
      // })
      //
      // console.log(records)
      //




      // var text = 'a,b,c,d,\n1,2,3,4'
      // var filename = "test.csv";
      //
      // this.download(filename, text);
    },
    editProfile (profile) {
      console.log('editProfile OptionsBlock')
      this.modal = true
      this.$emit('edit-profile', profile)
    },
    removeProfile (profile) {
      console.log('removeProfile: %o', profile)
      this.modal = true
      this.$emit('remove-profile', profile)
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    overflow-y: visible;

    &::v-deep .moe-block-header {
      min-height: 48px;
      height: 48px;
    }
    &::v-deep .moe-block-container {
      overflow-y: visible;
    }
    .container-content {
      display: flex;
      align-items: flex-end;

      .profile {
        flex-grow: 1;

        .select-row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          svg.remove-icon, svg.pencil-icon {
            width: 16px;
            height: 16px;
            opacity: 0.2;
            margin-left: 8px;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    &::v-deep .button-input {
      margin-left: 8px;

      .button-title {
        white-space: nowrap;
      }

      .button-icon {
        margin-right: 8px;
      }
    }
    &::v-deep .button-input.disabled .button-icon {
      svg path {
        fill: $grey30;
      }
      svg rect {
        fill: $grey30;
      }
    }
    &::v-deep .button-input.export-button {
      .upload-icon {
        transform: rotate(180deg)
      }
    }
    &::v-deep .button-input.primary {
      .button-icon svg path {
        fill: $white;
      }
    }
  }

</style>
