export default class CSV {
  static exportCSV (profile) {

    let file = profile.characteristics.map(item => {
      return item.name
    })
    file.unshift('uuid')
    const filename = `${profile.type}.csv`
    this.download(filename, file)
  }
  static download (filename, file) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  static importCSV (result, profileId) {
    // TODO: проверка на пустоту, проверка на первый столбец с uid
    const rows = result.split('\n')
    const paramsName = rows[0].split(',')
    paramsName.splice(0, 1)
    const cells = []
    rows.forEach((row, index) => {
      if (index !== 0 && index !== rows.length - 1) {
        cells.push(row.split(','))
      }
    })
    let devices = cells.map(cell => {
      return {
        uid: cell[0],
        profileId: profileId,

        params: paramsName.map((paramName, index) => {
          return {
            id: paramName.trim(),
            value: cell[index + 1]
          }
        })
      }
    })
    return devices
  }
}
