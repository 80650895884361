<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0812 9.3265L5.29936 14.7944L4 13.5656L12 6L20 13.5656L18.7006 14.7944L12.9188 9.3265L12.9188 22L11.0812 22L11.0812 9.3265Z" fill="#474B6D"/>
  <rect x="4" y="2" width="16" height="2" fill="#474B6D"/>
  </svg>
</template>

<script>
export default {
  name: "upload-icon",
  props: {
    fill: {
      default: "#474B6D",
      type: String
    }
  }
};
</script>
