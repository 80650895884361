<template>
  <moe-block :loading="loading" heading="Edit Device" class="container">
    <template #content>
      <div class="container-content">
        <div class="context-header">
          <info-block borderless class="info-uid">
            <template #title>{{ device && device.uid }}</template>
            <template #descr>UID</template>
          </info-block>
          <info-block borderless class="info-date">
            <template #title>{{ device && formatDate(device.createdAt) }}</template>
            <template #descr>Created</template>
          </info-block>
          <info-block borderless class="info-date">
            <template #title>{{ device && formatDate(device.updatedAt) }}</template>
            <template #descr>Updated</template>
          </info-block>
        </div>
        <div class="context-main">
          <table-component class="devices-table" v-bind="table">
            <template #row-id="props">
              {{ getParamName(props.item.id) }}
            </template>
            <template #row-value="props">
              <text-input placeholder="Value" v-model="data[props.item.name]" @input="inputHandler(props.item.name, $event)"/>
            </template>
            <template #row-validation="props">
              <div class="table-validation">{{ getConfigLabel(props.item.configId) }}</div>
              <div class="empty-icon"/>
              <checked-icon v-if="!$v.data[props.item.name].$invalid" class="checked-icon"/>
            </template>
          </table-component>
        </div>
        <div class="context-footer">
          <button-input @click.native="deleteDevice" :primary="false" class="red">
            <template v-slot:default>
              Delete Device
            </template>
          </button-input>
          <button-input @click.native="saveDevice" :disabled="$v.$invalid">
            <template v-slot:default>
              Save Device
            </template>
          </button-input>
        </div>
      </div>
    </template>
  </moe-block>
</template>

<script>
import Moment from "moment"

import MoeBlock from "@/components/Blocks/MoeBlock";
import TableComponent from "@/components/Table";
import InfoBlock from "@/components/Blocks/InfoBlock";
import TextInput from "@/components/Inputs/TextInput";
import ButtonInput from "@/components/Inputs/ButtonInput";
import CheckedIcon from "@/components/Icons/CheckedIcon";
import {DevicesApi} from "@/api";
// import Validation from "@/helpers/validation";
import { required, minLength, helpers, maxLength  } from 'vuelidate/lib/validators'

export default {
  name: "NewProfile",
  components: {InfoBlock, MoeBlock, TableComponent, TextInput, ButtonInput, CheckedIcon },
  props: ['selectedProfile', 'deviceList', 'configList'],
  data () {
    return {
      device: null,
      loading: false,
      data: {}
    }
  },
  computed: {
    table () {
      const columns = [
        { key: "name", title: "Param name" },
        { key: 'value', title: 'Value' },
        { key: 'validation', title: 'Validation' }
      ]
      let data = []
      if (this.selectedProfile) {
        const selectDevice = this.deviceList.find(device => device.uid === this.$route.params.id)
        data = this.selectedProfile.characteristics
        data.forEach(item => {
          this[item.name] = selectDevice.params.find(param => param.id === item.id).value
          console.log('selectDevice.params.find(param => param.id === item.id).value: %o', selectDevice.params.find(param => param.id === item.id).value)
          this.$set(this.data, item.name, selectDevice.params.find(param => param.id === item.id).value)
          // this.data[item.name] = selectDevice.params.find(param => param.id === item.id).value
          item.value = selectDevice.params.find(param => param.id === item.id).value
        })
      }
      return {
        columns,
        data
      }
    }
  },
  mounted () {
    // this.device = this.deviceList.find(device => device.uid === this.$route.params.id)
    // if (this.device) {
    //   this.table.data = this.device.params
    // }
    console.log('this: %o', this)
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.device = this.deviceList.find(device => device.uid === this.$route.params.id)
        if (this.device) {
          let data = []
          if (this.selectedProfile && this.deviceList.find(device => device.uid === this.$route.params.id)) {
            const selectDevice = this.deviceList.find(device => device.uid === this.$route.params.id)
            data = this.selectedProfile.characteristics
            data.forEach(item => {
              this.$set(this.data, item.name, selectDevice.params.find(param => param.id === item.id).value)
              // this.data[item.name] = selectDevice.params.find(param => param.id === item.id).value
              item.value = selectDevice.params.find(param => param.id === item.id).value
            })
          }
          this.table.data = data
        }
      }
    },
    deviceList: {
      immediate: true,
      handler() {
        this.device = this.deviceList.find(device => device.uid === this.$route.params.id)
        console.log('device: %o', this.device)
        if (this.device) {
          let data = []
          if (this.selectedProfile && this.deviceList.find(device => device.uid === this.$route.params.id)) {
            const selectDevice = this.deviceList.find(device => device.uid === this.$route.params.id)
            data = this.selectedProfile.characteristics
            data.forEach(item => {
              this.$set(this.data, item.name, selectDevice.params.find(param => param.id === item.id).value)
              // this.data[item.name] = selectDevice.params.find(param => param.id === item.id).value
              item.value = selectDevice.params.find(param => param.id === item.id).value
            })
          }
          this.table.data = data
        }
      }
    },
  },
  validations () {
    console.log('this.configList: %o', this.configList)
    console.log('this.selectedProfile: %o', this.selectedProfile)
    if (this.configList.length > 0 && this.selectedProfile) {
      let data = {}
      this.selectedProfile.characteristics.forEach(chars => {
        data[chars.name] = {}
        if (!chars.isNullable) {
          data[chars.name] = {
            required
          }
        }
        const configs = this.configList.find(c => c.id === chars.configId)
        configs.data.forEach(config => {
          if (config.name === 'type' && config.value === 'HEX_STRING') {
            data[chars.name].valid = helpers.regex('hex', /^[0-9A-Fa-f]+$/)
          } else if (config.name === 'type' && config.value === 'IP,NETMASK,GATEWAY,DNS') {
            data[chars.name].valid = helpers.regex('ip', /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/)
          } else if (config.name === 'maxLength') {
            data[chars.name].maxLength = maxLength(config.value)
          } else if (config.name === 'length') {
            data[chars.name].maxLength = maxLength(config.value)
            data[chars.name].minLength = minLength(config.value)
          }
        })
      })
      console.log('DATA: %o', data)
      return { data }
    } else {
      return {}
    }
  },
  methods: {
    formatDate(date) {
      return Moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    saveDevice () {
      console.log(this.table)
      let device = {
        uid: this.device.uid,
        profileId: this.selectedProfile.id,
        params: this.table.data.map(row => {
          return {
            id: row.id,
            value: this.data[row.name]
          }
        })
      }
      console.log(device)
      this.loading = true
      DevicesApi.updateDevice(device).then(r => {
        this.loading = false
        if (this.$route.fullPath !== '/') {
          this.$router.push(`/`)
          DevicesApi.getDevices()
        }
        console.log(r)
        this.$toast.success("Device was saved!");
      }).catch(e => {
        this.loading = false
        console.log(e.data)
        this.$toast.error(`Device wasn't saved! Error: ${e.status}`);
      })
    },
    // validation (value, valid) {
    //   console.log('validation: %o, %o', value, valid)
    //   // console.log('validation: %o, %o', this.$v['0xf000'], this.$v['0xf000'].$invalid)
    //   return Validation.valid(value, '0xf000')
    // },
    testInput (value) {
      console.log('value: %o', value)
      console.log(this.$v, this.$v.test.$invalid)
    },
    getParamName (id) {
      if (this.selectedProfile && this.selectedProfile.characteristics.find(c => c.id === id)) {
        return this.selectedProfile.characteristics.find(c => c.id === id).name
      } else {
        return 'Error'
      }

    },
    getConfigId (id) {
      if (this.selectedProfile && this.selectedProfile.characteristics.find(c => c.id === id)) {
        return this.selectedProfile.characteristics.find(c => c.id === id).configId
      } else {
        return 'Error'
      }
    },
    deleteDevice () {
      DevicesApi.deleteDevice(this.device).then(r => {
        console.log('Delete Device: %o', r)
        this.$emit('update')
        if (this.$route.fullPath !== '/') {
          this.$router.push(`/`)
        }

        this.$toast.success("Device was deleted!");
      }).catch(e => {
        console.log('Delete Device Error: %o', e)
      })
    },
    getConfigLabel (configId) {
      if (this.configList.length > 0) {
        const config = this.configList.find(item => item.id === configId)
        let configLabel = ''
        config.data.forEach((item, index) => {
          if (index === 0) {
            configLabel = `${item.name}: ${item.value}`
          } else {
            configLabel += `, ${item.name}: ${item.value}`
          }
        })
        return configLabel
      } else {
        return ''
      }
    },
    inputHandler (name, value) {
      console.log(name, value)
      console.log(this.$v)

      // console.log('name: %o', name)
      // this[name] = value
      // this.$v[name].$touch()
      // this.$set(this.$v[name], '$model', value)
      // // console.log(this.$v)
      // this.$v[name].$touch()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {

  &::v-deep .moe-block-header {
    min-height: 48px;
    height: 48px;
  }

  .container-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .context-header {
      display: flex;
      margin-bottom: 16px;

      .info-uid {
        flex-grow: 1;
      }
      .info-date {
        margin-left: 16px;
      }
    }
    .context-main {
      flex-grow: 1;

      .empty-icon {
        width: 40px;
        height: 40px;
        background: #eeeff8;
        border-radius: 100px;
        position: absolute;
        right: 0;
      }

      .checked-icon {
        position: absolute;
        right: 0;
      }

      &::v-deep .table-component {
        overflow-y: visible;

        td {
          padding: 0 8px 0 0;

          &:last-child {
            padding: 0;
          }

          button {
            padding: 0 8px;
          }
        }
      }
      .table-validation {
        color: $grey70;
        font-size: 10px;
      }
    }
    .context-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      &::v-deep .button-input.red {
        color: $accentRed;
      }
    }
  }
}
</style>
