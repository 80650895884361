<template>
  <div class="switch-input">
    <input
      type="checkbox"
      class="checkbox-native"
      :disabled="disabled"
      :checked="active"
      @click="toggle"
    />
    <div class="switch-thumb" />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    value: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.active = this.value;
      }
    }
  },

  computed: {
    switchClasses() {
      return {
        "switch-input--active": this.active,
        "switch-input--disabled": this.disabled
      };
    }
  },

  data() {
    return {
      active: false
    };
  },

  methods: {
    toggle() {
      this.active = !this.active;
      this.$emit("input", this.active);
    }
  }
};
</script>

<style lang="scss" scoped>
.switch-input {
  position: relative;
  width: 40px;
  height: 24px;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;

  .checkbox-native {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 46px;
    transition: background-color $defaultTransition;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;

    &:checked {
      background: $accentGreen;

      &:hover {
        background: darken($color: $accentGreen, $amount: $defaultDarkening);
      }

      + .switch-thumb {
        left: 21px;
      }
    }

    &:not(:checked) {
      background: $grey30;

      &:hover {
        background: darken($color: $grey30, $amount: $defaultDarkening);
      }
    }

    &:disabled {
      background: $grey10;
      pointer-events: none;
    }
  }

  .switch-thumb {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $white;
    transition: left $defaultTransition;
    pointer-events: none;
  }
}
</style>