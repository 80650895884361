

<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#37CB95" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7071 22.2929L27 13L28.4142 14.4142L17.7071 25.1213L12 19.4142L13.4142 18L17.7071 22.2929Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "checked-icon",
  props: {
    fill: {
      default: "#FFFFFF",
      type: String
    }
  }
};
</script>

