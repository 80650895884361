<template>
  <input style="display: none" type="file" ref="uploadFileInput" @input="onInputChanged" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },

  methods: {
    onInputChanged() {
      console.log('onInputChanged')
      if (this.type == "csv") {
        console.log('file: %o', event.target.files[0])
        event.target.files[0].text()
            .then((result) => {
              console.log('result onDrop: %o', result)
              this.$emit("import", result);
            })
            .catch(() => {
              this.$toast.error("File format is wrong!");
            });

        // this.$emit("import", file);
        this.$refs.uploadFileInput.value = "";
      }

      if (this.type == "json") {
        event.target.files[0]
          .text()
          .then(result => {
            this.$emit("import", JSON.parse(result));
          })
          .catch(() => {
            this.$toast.error("File format is wrong!");
          });
      }
    }
  }
};
</script>
