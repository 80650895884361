<template>
  <multiselect v-bind="attrs" class="select-input" :value="attrs.value" @input="onChange">
    <div slot="caret" class="chevron">
      <chevron-icon fill="currentColor" />
    </div>
    <template v-slot:singleLabel="{ option }">
      <slot name="single-label" v-bind:option="option" />
    </template>
    <template v-slot:option="{ option }">
      <slot name="option" v-bind:option="option" />
    </template>
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
import ChevronIcon from "@/components/Icons/Chevron";

export default {
  name: "select-input",

  components: {
    ChevronIcon,
    Multiselect
  },

  computed: {
    attrs() {
      return {
        ...this.$props,
        ...this.$attrs
      };
    }
  },

  methods: {
    onChange(val) {
      this.$emit("input", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.select-input {
  .chevron {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
    transition: transform $defaultDuration ease-out;
  }

  &.multiselect--active {
    .chevron {
      transform: rotate(0);
    }
  }
}
</style>
