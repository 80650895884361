<template>
  <moe-block :loading="loading" heading="New Device" class="container">
    <template #content>
      <div class="container-content">
        <div class="context-header">
          <label>Device UID</label>
          <text-input placeholder="UID" v-model.trim="deviceUID" />
        </div>

        <div class="context-main">
          <table-component class="devices-table" v-bind="table">
            <template #row-name="props">
              {{ props.item.name }}
            </template>
            <template #row-value="props">
              <text-input placeholder="Value" v-model="data[props.item.name]" @input="inputHandler(props.item.name, $event)"/>
            </template>
            <template #row-validation="props">
              <div class="table-validation">{{ getConfigLabel(props.item.configId) }}</div>
              <div class="empty-icon"/>
              <checked-icon v-if="!$v.data[props.item.name].$invalid" class="checked-icon" />
            </template>
          </table-component>
        </div>
        <div class="context-footer">
          <button-input @click.native="saveDevice" :disabled="$v.$invalid">
            <template v-slot:default>
              Save Device
            </template>
          </button-input>
        </div>
      </div>
    </template>
  </moe-block>
</template>

<script>
import MoeBlock from "@/components/Blocks/MoeBlock";
import ButtonInput from "@/components/Inputs/ButtonInput";
import TableComponent from "@/components/Table";
import TextInput from "@/components/Inputs/TextInput";

import CheckedIcon from "@/components/Icons/CheckedIcon";

import { DevicesApi } from '@/api'
// import Validation from "@/helpers/validation";
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'

export default {
  name: "NewDevice",
  components: { ButtonInput, TextInput, MoeBlock, TableComponent, CheckedIcon },
  props: ['selectedProfile', 'configList'],
  data () {
    return {
      deviceUID: '',
      loading: false,
      sensors: {},
      getSuggestionList: [],
      data: {}
    }
  },
  computed: {
    table () {
      const columns = [
        { key: "name", title: "Param name" },
        { key: 'value', title: 'Value' },
        { key: 'validation', title: 'Validation' }
      ]
      let data = []
      if (this.selectedProfile) {
        data = this.selectedProfile.characteristics
        data.forEach(item => this[item.name] = '')
      }

      return {
        columns,
        data
      }
    }
  },
  mounted () {
    if (this.selectedProfile) {
      // this.table.data = this.selectedProfile.params
      // console.log(this.table.data)
    }
    console.log(this.$v)
  },
  validations () {
    if (this.configList.length > 0 && this.selectedProfile) {
      let data = {}
      this.selectedProfile.characteristics.forEach(chars => {
        data[chars.name] = {}
        if (!chars.isNullable) {
          data[chars.name] = {
            required
          }
        }
        const configs = this.configList.find(c => c.id === chars.configId)
        configs.data.forEach(config => {
          if (config.name === 'type' && config.value === 'HEX_STRING') {
            data[chars.name].valid = helpers.regex('hex', /^[0-9A-Fa-f]+$/)
          } else if (config.name === 'type' && config.value === 'IP,NETMASK,GATEWAY,DNS') {
            data[chars.name].valid = helpers.regex('ip', /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/)
          } else if (config.name === 'maxLength') {
            data[chars.name].maxLength = maxLength(config.value)
          } else if (config.name === 'length') {
            data[chars.name].maxLength = maxLength(config.value)
            data[chars.name].minLength = minLength(config.value)
          }
        })
      })
      return { data }
    } else {
      return {}
    }
  },
  methods: {
    addParam () {

    },
    saveDevice () {
      console.log(this.table)
      let device = {
        uid: this.deviceUID,
        profileId: this.selectedProfile.id,
        params: this.table.data.map(row => {
          return {
            id: row.id,
            value: this.data[row.name]
          }
        })
      }
      console.log(device)
      this.loading = true
      DevicesApi.createDevice(device).then(r => {
        this.loading = false
        console.log(r)
        DevicesApi.getDevices()
        this.$emit('update')
        this.$toast.success("Device was saved!");
      }).catch(e => {
        this.loading = false
        console.log(e)
        this.$toast.error(`Device wasn't saved! ${e}`);
      })
    },
    // validation (name) {
    //   console.log('validation invalid name: %o', name, this.$v)
    //   // return !this.$v[name].$invalid
    //   return this.$v[name]
    // },
    inputHandler (name, value) {
      console.log(name, value)
      console.log(this.$v)
      // console.log('name: %o', name)
      // this[name] = value
      // this.$v[name].$touch()
      // this.$set(this.$v[name], '$model', value)
      // // console.log(this.$v)
      // this.$v[name].$touch()
    },
    getConfigLabel (configId) {
      if (this.configList.length > 0) {
        const config = this.configList.find(item => item.id === configId)
        let configLabel = ''
        config.data.forEach((item, index) => {
          if (index === 0) {
            configLabel = `${item.name}: ${item.value}`
          } else {
            configLabel += `, ${item.name}: ${item.value}`
          }
        })
        return configLabel
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {

  &::v-deep .moe-block-header {
    min-height: 48px;
    height: 48px;
  }

  .container-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .context-header {
      margin-bottom: 16px;
    }
    .context-main {
      flex-grow: 1;

      .empty-icon {
        width: 40px;
        height: 40px;
        background: #eeeff8;
        border-radius: 100px;
        position: absolute;
        right: 0;
      }

      .checked-icon {
        position: absolute;
        right: 0;
      }

      &::v-deep .table-component {
        overflow-y: visible;

        td {
          padding: 0 8px 0 0;

          &:last-child {
            padding: 0;
          }

          button {
            padding: 0 8px;
          }
        }
      }
      .table-validation {
        color: $grey70;
        font-size: 10px;
      }
    }
    .context-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
    }
  }
}
</style>
