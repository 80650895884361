import Request from '@/helpers/request'

export class ProfilesApi {
  static getProfiles() {
    return Request.get('profiles')
  }
  static createProfile (profile) {
    return Request.post('profile/create', profile)
  }
  static updateProfile (profile) {
    return Request.post('profile/update', profile)
  }
  static deleteProfile (profile) {
    console.log('profile: %o', profile)
    return Request.delete('profile/delete', {data: profile})
  }
}
export class DevicesApi {
  static getDevices() {
    return Request.get('devices')
  }
  static createDevices (devices) {
    return Request.post('devices/create', devices)
  }
  static createDevice(device) {
    return Request.post('device/create', device)
  }
  static updateDevice(device) {
    return Request.post('device/update', device)
  }
  static deleteDevice(device) {
    return Request.delete('device/delete', {data: { uid: device.uid }})
  }
}
export class ConfigsApi {
  static getConfigs() {
    return Request.get('configs')
  }
}
