<template>
  <moe-block :loading="loading" heading="Listing Devices" class="container">
    <template #content>
<!--      Listing Devices-->
<!--      {{ deviceList }}-->
      <table-component class="devices-table" v-bind="table">
        <template #row-uid="props">
          <router-link :to="'/device/' + props.item.uid">
            {{ props.item.uid }}
          </router-link>
          <div v-if="props.item.new">
            <div class="empty-icon"/>
            <checked-icon v-if="!props.item.valid" class="checked-icon" />
            <unchecked-icon v-else class="cross-icon"/>
          </div>
        </template>
      </table-component>
    </template>
  </moe-block>
</template>

<script>
import MoeBlock from "@/components/Blocks/MoeBlock";
import TableComponent from "@/components/Table";

import UncheckedIcon from "@/components/Icons/UncheckedIcon";
import CheckedIcon from "@/components/Icons/CheckedIcon";

export default {
name: "ListingDevicesBlock",
  components: {UncheckedIcon, MoeBlock, TableComponent, CheckedIcon },
  props: ['deviceList', 'loading'],
  data () {
    return {
      profiles: [
        'profile_test_1', 'profile_test_2', 'profile_test_3'
      ],
      profile: null,
      table: {
        columns: [
          { key: "uid", title: "UID" }
        ],
        data: []
      }
    }
  },
  watch: {
    deviceList (value) {
      console.log('Value: %o', value)
      this.table.data = value
    }
  },
  methods: {
    onPeriodChanged (event) {
      console.log(event)
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {

    &::v-deep .moe-block-header {
      min-height: 48px;
      height: 48px;
    }

    &::v-deep .devices-table {
      td[data-column="uid"] {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          width: 20px;
        }
      }
    }
  }
</style>
