import Vue from 'vue'
import Router from 'vue-router'
import NewDevice from '@/view/NewDevice'
import EditDevice from '@/view/EditDevice'
import NewProfile from '@/view/NewProfile'
import EditProfile from '@/view/EditProfile'
import EmptyPage from '@/view/EmptyPage'
import UploadConfigFile from '@/view/UploadConfigFile'

Vue.use(Router);

const router = new Router({
  base: "/",
  mode: "history",
  routes: [{
    path: '/device',
    name: 'NewDevice',
    component: NewDevice
  }, {
    path: '/device/:id',
    name: 'EditDevice',
    component: EditDevice
  }, {
    path: '/profile',
    name: 'NewProfile',
    component: NewProfile
  }, {
    path: '/profile/:id',
    name: 'EditProfile',
    component: EditProfile
  }, {
    path: '/upload',
    name: 'UploadConfigFile',
    component: UploadConfigFile
  }, {
    path: '*',
    name: 'EmptyPage',
    component: EmptyPage
  }]
})

export default router
