<template>
  <moe-block :loading="loading" heading="Edit Profile" class="container">
    <template #content>
      <div class="container-content">

        <div class="context-header">
          <label>Profile name</label>
          <text-input placeholder="Name" v-model="profileName" />
        </div>

        <div class="context-main">
          <label>Params</label>
          <table-component class="devices-table" v-bind="table">
            <template #row-id="props">
              <text-input placeholder="Id" v-model="props.item.id" />
            </template>
            <template #row-name="props">
              <text-input placeholder="Name" v-model="props.item.name" />
            </template>
            <template #row-default="props">
              <text-input placeholder="Value" v-model="props.item.default" />
            </template>
            <template #row-isNotifiable="props">
              <switch-input v-model="props.item.isNotifiable"
              />
            </template>
            <template #row-isEditable="props">
              <switch-input v-model="props.item.isEditable"
              />
            </template>
            <template #row-isNullable="props">
              <switch-input v-model="props.item.isNullable"
              />
            </template>
            <template #row-setting="props">
              <select-input v-model="props.item.configId" :options="configList" :allow-empty="false">
                <template #single-label="{ option }">{{ getSetting(option.id) }}</template>
                <template #option="{ option }">{{ getSetting(option.id) }}</template>
              </select-input>
            </template>
            <template #row-options="props">
              <button-input @click.native="removeParam(props.item)" :primary="false">
                <template v-slot:icon>
                  <remove-icon class="remove-icon" />
                </template>
              </button-input>
            </template>
          </table-component>
        </div>
        <div class="context-footer">
          <button-input @click.native="addParam" :primary="false">
            <template v-slot:default>
              Add param
            </template>
          </button-input>
          <button-input @click.native="saveProfile">
            <template v-slot:default>
              Save Profile
            </template>
          </button-input>
        </div>
      </div>
    </template>
  </moe-block>
</template>

<script>
import MoeBlock from "@/components/Blocks/MoeBlock";
import TextInput from "@/components/Inputs/TextInput";
import TableComponent from "@/components/Table";
import SwitchInput from "@/components/Inputs/SwitchInput";
import SelectInput from "@/components/Inputs/SelectInput";
import ButtonInput from "@/components/Inputs/ButtonInput";

import RemoveIcon from "@/components/Icons/Remove";

import { ProfilesApi } from '@/api'

export default {
  name: "EditProfile",
  components: { MoeBlock, TextInput, TableComponent, ButtonInput, SelectInput, SwitchInput, RemoveIcon },
  props: ['configList', 'profileList'],
  data () {
    return {
      loading: false,
      profileName: '',
      editedProfileId: null,
      table: {
        columns: [
          { key: "id", title: "Param Id" },
          { key: "name", title: "Param name" },
          { key: "default", title: "Default value" },
          { key: 'isNotifiable', title: 'Notifiable' },
          { key: 'isEditable', title: 'Editable' },
          { key: 'isNullable', title: 'Nullable' },
          { key: 'setting', title: 'Setting'},
          { key: 'options', title: 'Options'}
        ],
        data: [
          {
            name: '',
            isNotifiable: false,
            isEditable: true,
            isNullable: false,
            id: ''
          }
        ]
      },
    }
  },
  watch: {
    profileList: {
      immediate: true,
      handler() {
        this.setData()
      }
    },
    '$route.params.id': {
      immediate: true,
      handler() {
        this.setData()
      }
    },
    configList: {
      immediate: true,
      handler() {
        this.setData()
      }
    }
  },
  methods: {
    removeParam (item) {
      const index = this.table.data.findIndex(tableItem => tableItem.id === item.id)
      this.table.data.splice(index, 1)
    },
    getSetting (id) {
      let config = this.configList.find(config => config.id === id)
      let configString = ''
      for (let data of config.data) {
        configString += `${data.name}: ${data.value}; `
      }
      return configString
    },
    setData () {
      let profile = this.profileList.find(profile => profile.id === parseInt(this.$route.params.id))
      console.log('Profile: %o', profile)
      if (profile) {
        this.editedProfileId = profile.id
        this.profileName = profile.type
        this.table.data = profile.characteristics.map(char => {
          return {
            id: char.id,
            name: char.name,
            default: char.default ? char.default : null,
            isNotifiable: char.isNotifiable,
            isEditable: char.isEditable,
            isNullable: char.isNullable,
            configId: this.configList.find(config => config.id === char.configId)
          }
        })
      }
    },
    addParam () {
      this.table.data.push({
        name: '',
        isNotifiable: false,
        isEditable: true,
        isNullable: false,
        id: ''
      })
    },
    saveProfile () {
      console.log('Save: %o',this.table.data)
      // TODO: проверки перед сохранением
      const profile = {
        id: this.editedProfileId,
        type: this.profileName,
        characteristics: this.table.data.map(item => {
          return {
            id: item.id,
            isEditable: item.isEditable,
            isNotifiable: item.isNotifiable,
            isNullable: item.isNullable,
            name: item.name,
            configId: item.configId.id,
            default: item.default
          }
        })
      }
      console.log('profile: %o', profile)
      this.loading = true
      ProfilesApi.updateProfile(profile).then(r => {
        this.loading = false
        console.log('create r: %o', r)
        this.$emit('update')
        // this.$router.push(`/`)
        this.$toast.success("Profile was updated!");
      }).catch(e => {
        this.loading = false
        console.log('error: %o', e.message)
        console.log('error: %o', e.data)
        this.$toast.error(`Profile wasn't updated! ${e}`);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {

  &::v-deep .moe-block-header {
    min-height: 48px;
    height: 48px;
  }

  .container-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .context-header {
      margin-bottom: 16px;
    }

    .context-main {
      flex-grow: 1;

      &::v-deep .table-component {
        overflow-y: visible;
        grid-template-columns: 1fr 1fr 1fr 80px 80px 80px 1fr 50px !important;

        td {
          padding: 0 8px 0 0;

          &:last-child {
            padding: 0;
          }

          button {
            padding: 0 8px;
          }
        }
      }
    }

    .context-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
    }
  }
}
</style>
