import Vue from 'vue'
import App from '@/App.vue'
import Router from '@/router'
import VueToast from 'vue-toast-notification'
import Vuelidate from 'vuelidate'
import SweetModal from 'sweet-modal-vue/src/plugin.js'
import "@/styles/base.scss";

import 'vue-multiselect/dist/vue-multiselect.min.css';
Vue.use(VueToast, {
  position: "top"
});

Vue.use(SweetModal)
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router: Router,
  render: h => h(App),
}).$mount('#app')
