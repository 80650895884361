<template>
  <div class="empty-page">
    Choose a device or create a new device or profile
  </div>
</template>

<script>
export default {
name: "EmptyPage"
}
</script>

<style lang="scss" scoped>
  .empty-page {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 2em;
    line-height: 1.2;
  }
</style>
