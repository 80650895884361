<template>
  <moe-block heading="Upload config file" class="container">
    <template #content>
      <div
          class="drag-screen"
          :class="{ dragover }"
          @drop.prevent="onDrop"
          @dragover.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
      >
        <div class="inner">
          <h1 class="header">Drag and Drop files here</h1>
          <p class="sub-header">or</p>

          <button-input
              :primary="true"
              @click.native="addBtnClicked"
          >
            <plus-icon slot="icon" fill="currentColor" />
            <template>Add config file</template>
            <import @import="onImport" ref="import" type="csv" />
          </button-input>

          <p>Supported files: CSV</p>
        </div>
      </div>
    </template>
  </moe-block>
</template>

<script>
import MoeBlock from "@/components/Blocks/MoeBlock";
import ButtonInput from "@/components/Inputs/ButtonInput";
import PlusIcon from "@/components/Icons/Plus"
import Import from "@/components/Inputs/Import";

export default {
  name: "UploadConfigFile",
  components: { MoeBlock, ButtonInput, PlusIcon, Import },
  data() {
    return {
      dragover: false,
    };
  },
  methods: {
    addBtnClicked() {
      console.log(this.$refs.import.$refs.uploadFileInput)
      this.$refs.import.$refs.uploadFileInput.click();
    },
    onImport(formData) {
      console.log('onImport: %o', formData)
      this.$emit("import", formData);
    },
    onDrop(ev) {
      console.log('ev: %o', ev.dataTransfer.files)
      ev.dataTransfer.files[0]
          .text()
          .then((result) => {
            console.log('result onDrop: %o', result)
            this.$emit("import", result);
          })
          .catch(() => {
            this.$toast.error("File format is wrong!");
          });

      this.dragover = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.container {

  &::v-deep .moe-block-header {
    min-height: 48px;
    height: 48px;
  }
  &::v-deep .moe-block-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .drag-screen {
    width: 500px;
    height: 300px;
    border-radius: 6px;
    border: 3px dashed $grey50;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.1s;

    &.dragover {
      box-shadow: 0 0 17px 1px $grey10;
    }

    .inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & > * {
        margin: 5px 0;
      }

      .header {
        color: $notBlack;
        font-size: 24px;
        margin: 5px 0;
      }

      .sub-header {
        color: $grey70;
      }
    }
    &:last-child {
      color: $grey70;
    }
  }
}
</style>
