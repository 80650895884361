<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0001 8.50006C12.1317 8.4993 12.2621 8.52452 12.384 8.57429C12.5058 8.62405 12.6166 8.69738 12.7101 8.79006L16.7101 12.7901C16.8033 12.8833 16.8773 12.994 16.9277 13.1158C16.9782 13.2376 17.0042 13.3682 17.0042 13.5001C17.0042 13.6319 16.9782 13.7625 16.9277 13.8843C16.8773 14.0061 16.8033 14.1168 16.7101 14.2101C16.6168 14.3033 16.5061 14.3773 16.3843 14.4277C16.2625 14.4782 16.1319 14.5042 16.0001 14.5042C15.8682 14.5042 15.7376 14.4782 15.6158 14.4277C15.494 14.3773 15.3833 14.3033 15.2901 14.2101L12.0001 10.9001L8.70006 14.0801C8.60807 14.1823 8.49596 14.2646 8.37075 14.3215C8.24553 14.3785 8.10991 14.4091 7.97236 14.4112C7.8348 14.4134 7.69828 14.3872 7.57132 14.3342C7.44437 14.2812 7.32971 14.2026 7.23453 14.1033C7.13934 14.004 7.06567 13.8861 7.01813 13.757C6.97059 13.6279 6.95019 13.4904 6.95822 13.353C6.96625 13.2157 7.00253 13.0815 7.06479 12.9588C7.12705 12.8361 7.21395 12.7276 7.32006 12.6401L11.3201 8.78006C11.5029 8.60374 11.7461 8.50363 12.0001 8.50006Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "chevron-icon",

  props: {
    fill: {
      type: String,
      default: "#474B6D"
    }
  }
};
</script>